const pathEnv = process.env.GATSBY_ACTIVE_ENV || '.env'

console.log('process.env ----> ', pathEnv)

require("dotenv").config({
    path: pathEnv,
})

const languages = [`es`, `en`, `de`, `fr`, 'it', 'nl', 'pl'];
const siteId = 3;
const experiences = [
    717,
    718,
    719,
    720,
    721,
    722,
    723,
    724,
    725,
    726,
    727,
    728,
    729,
    730,
    731,
    732,
    733,
    734,
    737,
    738,
    739,
    739,
    740,
    741,
    742,
    743,
    744,
]

module.exports = {
    siteMetadata: {
        title: "siteTitle",
        description: "siteDescription",
        siteUrl: "https://www.tamaran.com",
        siteId: siteId,
        opinions: [
            {
                stars: "stars1",
                date: "date1",
                text: "opinion1",
            },
            {
                stars: "stars2",
                date: "date2",
                text: "opinion2",
            },
            {
                stars: "stars3",
                date: "date3",
                text: "opinion3",
            },
        ],
        islandSelector: [
            {
                hierro: 182,
                palma: 181,
                gomera: 183,
                tenerife: 179,
                granCanaria: 180,
                fuerteventura: 185,
                lanzarote: 184,
            },
        ],
        menuLinks: [
            {
                name: "JeepSafari",
                section: 27,
                image: "jeep.jpg",
                description: "descriptionJeepSafari",
                carouselTitle: "carouselTitleJeepSafari",
                children: [
                    {
                        name: "JeepSafari",
                        article: 175,
                    },
                    {
                        name: "DesdeTenerife",
                        anchor: "true",
                        article: 179,
                    },
                    {
                        name: "TeideMedioDia",
                        experience: 717,
                    },
                    {
                        name: "TeideMasca",
                        experience: 718,
                    },
                    {
                        name: "Masca",
                        experience: 719,
                    },
                    {
                        name: "Gomera",
                        experience: 720,
                    },
                    {
                        name: "Palma",
                        experience: 737,
                    },
                    {
                        name: "Hierro",
                        experience: 721,
                    },
                    {
                        name: "DesdeGranCanaria",
                        anchor: "true",
                        article: 180,
                    },
                    {
                        name: "GranCanaria",
                        experience: 739,
                    },
                    {
                        name: "DesdeLanzarote",
                        anchor: "true",
                        article: 184,
                    },
                    {
                        name: "RutaVolkanica",
                        experience: 741,
                    },
                    {
                        name: "RutaNorte",
                        experience: 742,
                    },
                    {
                        name: "DesdeFuerteventura",
                        anchor: "true",
                        article: 185,
                    },
                    {
                        name: "ParqueNaturalCofete",
                        experience: 743,
                    },
                ],
            },
            {
                name: "VipTour",
                section: 28, // fake
                image: "vip.jpg",
                description: "descriptionVipTour",
                carouselTitle: "carouselTitleVipTour",
                children: [
                    {
                        name: "VipTour",
                        article: 177,
                    },
                    {
                        name: "DesdeTenerife",
                        anchor: "true",
                        article: 179,
                    },
                    {
                        name: "VueltaIsla",
                        experience: 722,
                    },
                    {
                        name: "TeideMasca",
                        experience: 723,
                    },
                    {
                        name: "AnagaLaguna",
                        experience: 724,
                    },
                    {
                        name: "Gomera",
                        experience: 725,
                    },
                    {
                        name: "DesdeGranCanaria",
                        anchor: "true",
                        article: 180,
                    },
                    {
                        name: "PanoramaGranCanaria",
                        experience: 740,
                    },
                ],
            },
            {
                name: "ExcursionesPrivadas",
                section: 29, // fake
                image: "excursiones.jpg",
                description: "descriptionExcursionesPrivadas",
                carouselTitle: "carouselTitleExcursionesPrivadas",
                children: [
                    {
                        name: "ExcursionesPrivadas",
                        article: 178,
                    },
                    {
                        name: "JeepSafari",
                        anchor: "jeep-safari",
                        article: 178,
                    },
                    {
                        name: "TeideMedioDia",
                        experience: 726,
                    },
                    {
                        name: "TeideMasca",
                        experience: 727,
                    },
                    {
                        name: "Masca",
                        experience: 728,
                    },
                    {
                        name: "Gomera",
                        experience: 729,
                    },
                    {
                        name: "Palma",
                        experience: 738,
                    },
                    {
                        name: "VipTour",
                        anchor: "vipTour",
                        article: 178,
                    },
                    {
                        name: "VueltaIsla",
                        experience: 731,
                    },
                    {
                        name: "TeideMasca",
                        experience: 732,
                    },
                    {
                        name: "AnagaLaguna",
                        experience: 733,
                    },
                    {
                        name: "Gomera",
                        experience: 734,
                    },
                    {
                        name: "",
                        experience: 729,
                        hidden: true,
                    },
                    {
                        name: "",
                        experience: 730,
                        hidden: true,
                    },
                    {
                        name: "",
                        experience: 738,
                        hidden: true,
                    },
                ],
            },
            {
                name: "Islas",
                section: 27,
                image: "jeep.jpg",
                description: "descriptionIslands",
                carouselTitle: "carouselTitleIslands",
                children: [
                    {
                        name: "Tenerife",
                        article: 179,
                        lowercaseText: true,
                    },
                    {
                        name: "Gomera",
                        article: 183,
                        lowercaseText: true,
                    },
                    {
                        name: "Fuerteventura",
                        article: 185,
                        lowercaseText: true,
                    },
                    {
                        name: "GranCanaria",
                        article: 180,
                        lowercaseText: true,
                    },
                    {
                        name: "Palma",
                        article: 181,
                        lowercaseText: true,
                    },
                    {
                        name: "Lanzarote",
                        article: 184,
                        lowercaseText: true,
                    },
                    {
                        name: "Hierro",
                        article: 182,
                        lowercaseText: true,
                    },
                ],
            },
            {
                name: "RentACar",
                url: "https://volcanicrentacar.com",
            },
            {
                name: "Contacto",
                article: 192,
            },
        ],
        addresses: [
            {
                styleClass: "col-lg-3 my-2 my-lg-0 text-center text-lg-left",
                name: "Tamarán Norte",
                address: "dir1",
                phonetext: "tel",
                phone: "phone1",
                whatsapptext: "Whatsapp – Telegram",
                whatsapp: "whatsapp",
                email: "email",
                map:
                    "https://www.google.es/maps/place/Calle+Acevi%C3%B1o,+12,+38400+Puerto+de+la+Cruz,+Santa+Cruz+de+Tenerife/@28.4154049,-16.5364051,17z/data=!3m1!4b1!4m5!3m4!1s0xc41d55f9297d1d9:0x6da2a9483b2cb7f7!8m2!3d28.4154002!",
            },
            {
                styleClass:
                    "col-lg-3 col-xl-4 col-xxl-3 my-2 my-lg-0 text-center text-lg-left",
                name: "Tamarán Sur",
                address: "dir2",
                phonetext: "tel",
                phone: "phone2",
                whatsapptext: "Whatsapp – Telegram",
                whatsapp: "whatsapp",
                email: "email",
                map:
                    "https://www.google.es/maps/place/Tamar%C3%A1n/@28.0659127,-16.7311857,17z/data=!3m1!4b1!4m5!3m4!1s0xc6a97637ef8cb0d:0x96fd6ae9648605ea!8m2!3d28.065908!4d-16.728997",
            },
        ],
        facebook: "https://www.facebook.com/tamaranroutes",
        twitter: "https://twitter.com/tamaranroutes",
        instagram: "https://www.instagram.com/tamaranroutes/",
        tripAdvisor:
            "https://www.tripadvisor.es/Attraction_Review-g230096-d7810487-Reviews-Tamaran-Arona_Tenerife_Canary_Islands.html",
        footer: [
            {
                name: "La empresa",
                styleClass:
                    "col-lg-3 col-xl-2 col-xxl-3 my-2 my-md-0 text-center text-lg-left",
                articles: [
                    {
                        name: "Sobre nosotros",
                        article: 188,
                    },
                    {
                        name: "Contacto",
                        article: 192,
                    },
                    {
                        name: "Preguntas frecuentes",
                        article: 193,
                    },
                    {
                        name: "Términos y condiciones",
                        article: 195,
                    },
                    {
                        name: "Aviso Legal",
                        article: 197,
                    },
                    {
                        name: "Política de privacidad",
                        article: 198,
                    },
                    {
                        name: "Cookies",
                        article: 196,
                    },
                    {
                        name: "Transparencia",
                        article: 232,
                    },
                    {
                        name: "Gestionar Cookies",
                        btn: "show-preferencesModal",
                    }
                ],
            },
        ],
        cookies: {
            translations: {
                de: {
                    consentModal: {
                        title: "Cookies?",
                        description: "Wir verwenden Cookies, um Ihr Erlebnis zu verbessern und Ihre Präferenzen beim Navigieren auf unserer Website zu speichern.",
                        acceptAllBtn: "Alle akzeptieren",
                        acceptNecessaryBtn: "Alles ablehnen",
                        showPreferencesBtn: "Einstellungen verwalten",
                        footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Nutzungsbedingungen</a>"
                    },
                    preferencesModal: {
                        title: "Einwilligungspräferenzen",
                        acceptAllBtn: "Alle akzeptieren",
                        acceptNecessaryBtn: "Alles ablehnen",
                        savePreferencesBtn: "Präferenzen speichern",
                        closeIconLabel: "Modal schließen",
                        serviceCounterLabel: "Dienste",
                        sections: [
                            {
                                title: "Verwendung von Cookies",
                                description: "Cookies sind kleine Textdateien, die Ihr Browser auf Ihrem Gerät speichert, um bestimmte Informationen zu speichern. Mit der gespeicherten und zurückgesendeten Information kann eine Website erkennen, dass Sie sie zuvor mit dem Browser auf Ihrem Endgerät besucht haben. Wir verwenden diese Informationen, um die Website optimal nach Ihren Präferenzen zu organisieren und anzuzeigen. In diesem Prozess wird nur das Cookie auf Ihrem Gerät identifiziert. Persönliche Daten werden nur nach Ihrer ausdrücklichen Zustimmung oder wenn es absolut notwendig ist, um die Nutzung des von uns bereitgestellten Dienstes zu ermöglichen, gespeichert."
                            },
                            {
                                title: "Unbedingt erforderliche Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
                                description: "Diese Cookies sind grundlegend für die Funktionsweise der Website und können in unseren Systemen nicht deaktiviert werden. Sie werden normalerweise nur als Reaktion auf Ihre Aktionen beim Anfordern von Diensten gesetzt, wie zum Beispiel das Einstellen Ihrer Datenschutzeinstellungen, das Einloggen oder das Ausfüllen von Formularen.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Funktionalitäts-Cookies",
                                description: "Diese Cookies ermöglichen es der Website, verbesserte Funktionalität und Personalisierung zu bieten, wie das Erinnern an Ihre früheren Auswahlmöglichkeiten und Änderungen an der Benutzeroberfläche. Wenn Sie diese Cookies nicht zulassen, funktionieren einige oder alle dieser Dienste möglicherweise nicht richtig.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Analytische Cookies",
                                description: "Analytische Cookies helfen uns zu verstehen, wie Besucher mit der Website interagieren, indem sie Informationen anonym sammeln und melden. Dies ermöglicht es uns, unser Angebot kontinuierlich zu verbessern, um Ihnen ein optimales Surferlebnis zu bieten.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Soziale und Werbe-Cookies",
                                description: "Soziale Cookies integrieren Social Media-Plattformen auf unserer Website, um Benutzern das Teilen von Inhalten und die Interaktion mit anderen Plattformen zu ermöglichen. Werbe-Cookies sammeln Informationen, um Anzeigen an Ihre Interessen anzupassen und die Relevanz der Anzeigenkampagnen zu verbessern, die Sie sehen. Beide tragen zu einem personalisierten Benutzererlebnis bei und können dazu beitragen, die von uns angebotenen Dienste aufrechtzuerhalten.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                pl: {
                    consentModal: {
                        title: "Czym są ciasteczka?",
                        description: "Używamy ciasteczek, aby poprawić Twoje doświadczenie i zapamiętać Twoje preferencje podczas przeglądania naszej strony internetowej.",
                        acceptAllBtn: "Zaakceptuj wszystkie",
                        acceptNecessaryBtn: "Odrzuć wszystkie",
                        showPreferencesBtn: "Zarządzaj ustawieniami",
                        footer: "<a href=\"#link_cookies\">Polityka ciasteczek</a>\n<a href=\"#link_terms\">Warunki użytkowania</a>"
                    },
                    preferencesModal: {
                        title: "Preferencje zgody",
                        acceptAllBtn: "Zaakceptuj wszystkie",
                        acceptNecessaryBtn: "Odrzuć wszystkie",
                        savePreferencesBtn: "Zapisz preferencje",
                        closeIconLabel: "Zamknij modal",
                        serviceCounterLabel: "Usługi",
                        sections: [
                            {
                                title: "Użycie ciasteczek",
                                description: "Ciasteczka to małe pliki tekstowe, które Twoja przeglądarka umieszcza na Twoim urządzeniu w celu przechowywania pewnych informacji. Korzystając z informacji, które są przechowywane i zwracane, strona internetowa może rozpoznać, że odwiedziłeś ją wcześniej za pomocą przeglądarki na Twoim końcowym urządzeniu. Używamy tych informacji, aby organizować i wyświetlać stronę internetową optymalnie według Twoich preferencji. W tym procesie identyfikowane jest tylko ciasteczko na Twoim urządzeniu. Dane osobowe są przechowywane tylko po wyraźnej zgody lub gdy jest to absolutnie konieczne, aby umożliwić korzystanie z usługi świadczonej przez nas, z której korzystasz."
                            },
                            {
                                title: "Koniecznie potrzebne ciasteczka <span class=\"pm__badge\">Zawsze aktywne</span>",
                                description: "Te ciasteczka są niezbędne do prawidłowego funkcjonowania strony internetowej i nie mogą być wyłączone w naszych systemach. Zazwyczaj są one ustawiane tylko w odpowiedzi na działania wykonywane przez Ciebie, które stanowią prośbę o usługi, takie jak ustawienie preferencji prywatności, logowanie się lub wypełnianie formularzy.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Ciasteczka funkcjonalne",
                                description: "Te ciasteczka pozwalają stronie internetowej oferować lepszą funkcjonalność i personalizację, taką jak zapamiętywanie Twoich wcześniejszych wyborów i zmian w interfejsie użytkownika. Jeśli nie zezwolisz na te ciasteczka, niektóre lub wszystkie te usługi mogą nie działać poprawnie.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Ciasteczka analityczne",
                                description: "Ciasteczka analityczne pomagają nam zrozumieć, jak odwiedzający wchodzą w interakcję ze stroną internetową, zbierając i zgłaszając informacje anonimowo. Pozwala nam to na ciągłe doskonalenie naszej oferty, aby zapewnić Ci optymalne doświadczenie przeglądania.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Ciasteczka społecznościowe i reklamowe",
                                description: "Ciasteczka społecznościowe integrują platformy mediów społecznościowych na naszej stronie internetowej, pozwalając użytkownikom na udostępnianie treści i interakcję z innymi platformami. Ciasteczka reklamowe zbierają informacje w celu dostosowania reklam do Twoich zainteresowań, co zwiększa trafność kampanii reklamowych, które widzisz. Oba przyczyniają się do spersonalizowanego doświadczenia użytkownika i mogą pomóc w utrzymaniu usług, które oferujemy.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                nl: {
                    consentModal: {
                        title: "Wat zijn cookies?",
                        description: "We gebruiken cookies om uw ervaring te verbeteren en uw voorkeuren te onthouden tijdens het navigeren op onze website.",
                        acceptAllBtn: "Alles accepteren",
                        acceptNecessaryBtn: "Alles afwijzen",
                        showPreferencesBtn: "Voorkeuren beheren",
                        footer: "<a href=\"#link_cookies\">Cookiebeleid</a>\n<a href=\"#link_terms\">Gebruiksvoorwaarden</a>"
                    },
                    preferencesModal: {
                        title: "Voorkeuren voor toestemming",
                        acceptAllBtn: "Alles accepteren",
                        acceptNecessaryBtn: "Alles afwijzen",
                        savePreferencesBtn: "Voorkeuren opslaan",
                        closeIconLabel: "Modal sluiten",
                        serviceCounterLabel: "Diensten",
                        sections: [
                            {
                                title: "Gebruik van cookies",
                                description: "Cookies zijn kleine tekstbestanden die uw browser op uw apparaat opslaat om bepaalde informatie vast te leggen. Met behulp van de opgeslagen en teruggestuurde informatie kan een website herkennen dat u deze eerder hebt bezocht met de browser op uw eindapparaat. We gebruiken deze informatie om de website optimaal in te richten en weer te geven volgens uw voorkeuren. In dit proces wordt alleen het cookie op uw apparaat geïdentificeerd. Persoonlijke gegevens worden alleen opgeslagen na uw uitdrukkelijke toestemming of wanneer dit absoluut noodzakelijk is om gebruik te kunnen maken van de door ons verstrekte dienst waartoe u toegang heeft."
                            },
                            {
                                title: "Strikt noodzakelijke cookies <span class=\"pm__badge\">Altijd actief</span>",
                                description: "Deze cookies zijn essentieel voor de werking van de website en kunnen niet worden uitgeschakeld in onze systemen. Ze worden meestal alleen ingesteld als reactie op acties van u die een verzoek om diensten vormen, zoals het instellen van uw privacyvoorkeuren, inloggen of formulieren invullen.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Functionele cookies",
                                description: "Deze cookies stellen de website in staat om verbeterde functionaliteit en personalisatie te bieden, zoals het onthouden van uw eerdere keuzes en wijzigingen in de gebruikersinterface. Als u deze cookies niet toestaat, kunnen sommige of alle van deze diensten mogelijk niet correct functioneren.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Analytische cookies",
                                description: "Analytische cookies helpen ons te begrijpen hoe bezoekers omgaan met de website door anoniem informatie te verzamelen en te rapporteren. Dit stelt ons in staat om ons aanbod continu te verbeteren om u een optimale browse-ervaring te bieden.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Sociale en advertentiecookies",
                                description: "Sociale cookies integreren sociale media platforms in onze website, waardoor gebruikers inhoud kunnen delen en met andere platforms kunnen communiceren. Advertentiecookies verzamelen informatie om advertenties aan uw interesses aan te passen, waardoor de relevantie van de advertentiecampagnes die u ziet, wordt verbeterd. Beide dragen bij aan een gepersonaliseerde gebruikerservaring en kunnen helpen om de diensten die wij aanbieden in stand te houden.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                it: {
                    consentModal: {
                        title: "Cosa sono i cookie?",
                        description: "Utilizziamo i cookie per migliorare la tua esperienza e ricordare le tue preferenze quando navighi sul nostro sito.",
                        acceptAllBtn: "Accetta tutto",
                        acceptNecessaryBtn: "Rifiuta tutto",
                        showPreferencesBtn: "Gestisci preferenze",
                        footer: "<a href=\"#link_policy\">Politica sulla privacy</a>\n<a href=\"#link_terms\">Termini e condizioni</a>"
                    },
                    preferencesModal: {
                        title: "Preferenze del consenso",
                        acceptAllBtn: "Accetta tutto",
                        acceptNecessaryBtn: "Rifiuta tutto",
                        savePreferencesBtn: "Salva preferenze",
                        closeIconLabel: "Chiudi modale",
                        serviceCounterLabel: "Servizi",
                        sections: [
                            {
                                title: "Uso dei Cookie",
                                description: "I cookie sono piccoli file di testo che il tuo browser salva sul tuo dispositivo per memorizzare determinate informazioni. Usando le informazioni memorizzate e restituite, un sito web può riconoscere che hai visitato precedentemente utilizzando il browser sul tuo dispositivo finale. Utilizziamo queste informazioni per organizzare e visualizzare il sito web in modo ottimale secondo le tue preferenze. In questo processo, solo il cookie sul tuo dispositivo viene identificato. I dati personali vengono memorizzati solo dopo il tuo consenso esplicito o quando è assolutamente necessario per permetterti di utilizzare il servizio fornito da noi e a cui accedi."
                            },
                            {
                                title: "Cookie Strettamente Necessari <span class=\"pm__badge\">Sempre attivi</span>",
                                description: "Questi cookie sono essenziali per il funzionamento del sito web e non possono essere disattivati nei nostri sistemi. Di solito vengono impostati solo in risposta alle tue azioni effettuate per richiedere servizi, come impostare le tue preferenze sulla privacy, effettuare l'accesso o compilare moduli.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Cookie di Funzionalità",
                                description: "Questi cookie permettono al sito web di offrire funzionalità e personalizzazione migliorate, come ricordare le tue scelte precedenti e i cambiamenti nell'interfaccia utente. Se non consenti questi cookie, alcuni o tutti questi servizi potrebbero non funzionare correttamente.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Cookie Analitici",
                                description: "I cookie analitici ci aiutano a capire come i visitatori interagiscono con il sito web, raccogliendo e riportando informazioni in modo anonimo. Questo ci permette di migliorare continuamente la nostra offerta per offrirti un'esperienza di navigazione ottimale.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Cookie Sociali e Pubblicitari",
                                description: "I cookie sociali integrano piattaforme di social media nel nostro sito web, consentendo agli utenti di condividere contenuti e interagire con altre piattaforme. I cookie pubblicitari raccolgono informazioni per adattare gli annunci ai tuoi interessi, migliorando la rilevanza delle campagne pubblicitarie che visualizzi. Entrambi contribuiscono a un'esperienza utente personalizzata e possono aiutare a mantenere i servizi che offriamo.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                fr: {
                    consentModal: {
                        title: "Qu'est-ce que les cookies ?",
                        description: "Nous utilisons des cookies pour améliorer votre expérience et mémoriser vos préférences de navigation sur notre site.",
                        acceptAllBtn: "Accepter tout",
                        acceptNecessaryBtn: "Rejeter tout",
                        showPreferencesBtn: "Gérer les préférences",
                        footer: "<a href=\"#link_policy\">Politique de confidentialité</a>\n<a href=\"#link_terms\">Conditions d'utilisation</a>"
                    },
                    preferencesModal: {
                        title: "Préférences de consentement",
                        acceptAllBtn: "Accepter tout",
                        acceptNecessaryBtn: "Rejeter tout",
                        savePreferencesBtn: "Enregistrer les préférences",
                        closeIconLabel: "Fermer le modal",
                        serviceCounterLabel: "Services",
                        sections: [
                            {
                                title: "Utilisation des cookies",
                                description: "Les cookies sont de petits fichiers textes placés sur votre appareil par votre navigateur pour stocker certaines informations. En utilisant les informations stockées et renvoyées, un site web peut reconnaître que vous l'avez visité précédemment via le navigateur sur votre appareil final. Nous utilisons ces informations pour organiser et afficher le site web de manière optimale selon vos préférences. Dans ce processus, seul le cookie sur votre appareil est identifié. Les données personnelles ne sont stockées qu'après votre consentement explicite ou lorsque cela est absolument nécessaire pour permettre l'utilisation du service fourni par nous et auquel vous accédez."
                            },
                            {
                                title: "Cookies strictement nécessaires <span class=\"pm__badge\">Toujours activés</span>",
                                description: "Ces cookies sont essentiels pour le fonctionnement du site web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement configurés en réponse à vos actions pour demander des services, tels que la configuration de vos préférences de confidentialité, la connexion ou le remplissage de formulaires.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Cookies de fonctionnalité",
                                description: "Ces cookies permettent au site web d'offrir une fonctionnalité et une personnalisation améliorées, comme se souvenir de vos sélections précédentes et des modifications de l'interface utilisateur. Si vous n'autorisez pas ces cookies, certains ou tous ces services pourraient ne pas fonctionner correctement.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Cookies analytiques",
                                description: "Les cookies analytiques nous aident à comprendre comment les visiteurs interagissent avec le site web, en collectant et en rapportant des informations de manière anonyme. Cela nous permet d'améliorer continuellement notre offre pour vous fournir une expérience de navigation optimale.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Cookies sociaux et publicitaires",
                                description: "Les cookies sociaux intègrent des plateformes de médias sociaux dans notre site web, permettant aux utilisateurs de partager du contenu et d'interagir avec d'autres plateformes. Les cookies publicitaires collectent des informations pour adapter les annonces à vos intérêts, améliorant la pertinence des campagnes publicitaires que vous voyez. Les deux contribuent à une expérience utilisateur personnalisée et peuvent aider à maintenir les services que nous offrons.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                en: {
                    consentModal: {
                        title: "cookies?",
                        description: "We use cookies to enhance your experience and remember your preferences when navigating our site.",
                        acceptAllBtn: "Accept all",
                        acceptNecessaryBtn: "Reject all",
                        showPreferencesBtn: "Manage preferences",
                        footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Terms and Conditions</a>"
                    },
                    preferencesModal: {
                        title: "Consent Preferences",
                        acceptAllBtn: "Accept all",
                        acceptNecessaryBtn: "Reject all",
                        savePreferencesBtn: "Save preferences",
                        closeIconLabel: "Close modal",
                        serviceCounterLabel: "Services",
                        sections: [
                            {
                                title: "Use of Cookies",
                                description: "Cookies are small text files that your browser places on your device to store certain information. By using the information that is stored and returned, a website can recognize that you have previously visited using the browser on your end device. We use this information to organize and display the website optimally according to your preferences. Within this process, only the cookie on your device is identified. Personal data is only stored after your express consent or when it is absolutely necessary to allow the use of the service provided by us and which you access."
                            },
                            {
                                title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                                description: "These cookies are fundamental for the functioning of the website and cannot be disabled in our systems. They are usually set only in response to your actions when requesting services, such as setting your privacy preferences, logging in, or filling out forms.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Functionality Cookies",
                                description: "These cookies enable the website to offer enhanced functionality and personalization, such as remembering your previous selections and changes to the user interface. If you do not allow these cookies, some or all of these services may not function properly.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Analytical Cookies",
                                description: "Analytical cookies help us understand how visitors interact with the website by collecting and reporting information anonymously. This allows us to continuously improve our offering to provide you with an optimal browsing experience.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Social and Advertising Cookies",
                                description: "Social cookies integrate social media platforms into our website, allowing users to share content and interact with other platforms. Advertising cookies collect information to tailor ads to your interests, enhancing the relevance of the advertising campaigns you see. Both contribute to a personalized user experience and can help sustain the services we offer.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                },
                es: {
                    consentModal: {
                        title: "¿Cookies?",
                        description: "Utilizamos cookies para mejorar tu experiencia y recordar tus preferencias al navegar en nuestro sitio.",
                        acceptAllBtn: "Aceptar todo",
                        acceptNecessaryBtn: "Rechazar todo",
                        showPreferencesBtn: "Gestionar preferencias",
                        footer: "<a href=\"#link_cookies\">Cookies</a>\n<a href=\"#link_terms\">Términos y condiciones</a>"
                    },
                    preferencesModal: {
                        title: "Preferencias de Consentimiento",
                        acceptAllBtn: "Aceptar todo",
                        acceptNecessaryBtn: "Rechazar todo",
                        savePreferencesBtn: "Guardar preferencias",
                        closeIconLabel: "Cerrar modal",
                        serviceCounterLabel: "Servicios",
                        sections: [
                            {
                                title: "Uso de Cookies",
                                description: "Las cookies son pequeños archivos de texto que su navegador coloca en su dispositivo para almacenar cierta información. Al usar la información que se almacena y se devuelve, un sitio web puede reconocer que usted ha accedido y visitado previamente usando el navegador en su dispositivo final. Utilizamos esta información para organizar y mostrar el sitio web de manera óptima de acuerdo con sus preferencias. Dentro de este proceso, solo se identifica la cookie en su dispositivo. Los datos personales solo se almacenan después de su consentimiento expreso o cuando esto es absolutamente necesario para permitir el uso del servicio proporcionado por nosotros y al que usted accede."
                            },
                            {
                                title: "Cookies Estrictamente Necesarias <span class=\"pm__badge\">Siempre Habilitado</span>",
                                description: "Estas cookies son fundamentales para el funcionamiento del sitio web y no se pueden desactivar en nuestros sistemas. Por lo general, se configuran solo en respuesta a tus acciones realizadas al solicitar servicios, como configurar tus preferencias de privacidad, iniciar sesión o llenar formularios.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Cookies de Funcionalidad",
                                description: "Estas cookies permiten que el sitio web ofrezca una funcionalidad y personalización mejoradas, como recordar tus selecciones anteriores y cambios en la interfaz de usuario. Si no permites estas cookies, algunos o todos estos servicios podrían no funcionar correctamente.",
                                linkedCategory: "functionality"
                            },
                            {
                                title: "Cookies Analíticas",
                                description: "Las cookies analíticas nos ayudan a entender cómo interactúan los visitantes con el sitio web, recogiendo y reportando información de manera anónima. Esto nos permite mejorar continuamente nuestra oferta para proporcionarte una experiencia de navegación óptima.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Cookies Sociales y Publicitarias",
                                description: "Las cookies sociales integran plataformas de redes sociales en nuestro sitio web, permitiendo a los usuarios compartir contenido e interactuar con otras plataformas. Las cookies publicitarias recopilan información para adaptar los anuncios a tus intereses, mejorando la relevancia de las campañas publicitarias que ves. Ambas contribuyen a una experiencia de usuario personalizada y pueden ayudar a mantener los servicios que ofrecemos.",
                                linkedCategory: "social"
                            }
                        ]
                    }
                }
            }
        },
        resources: {
            i18n: ["Autorización_menores_Esp.pdf"],
        },
        languages: languages,
        experiences: experiences,
        cookiesPolicyArticle: 196,
        termsAndConditionsArticle: 195,
        googleAPIKey: "AIzaSyBgqV_CqjpBfXK267Npck3Hu8ZI9mzQnLU",
        googleReCaptchaAPIKey: "6Lcz4iEeAAAAABO7X81Hv_dplhlJg7PfvqyYpGH_",
        environment: process.env.ENVIRONMENT,
        gtmTrackingId: process.env.GTM_TRACKING_ID,
        gaTrackingId: process.env.GA_TRACKING_ID,
        volcanoTeideAPIConfig: {
            protocol: process.env.API_URL_PROTOCOL,
            host: process.env.API_URL,
            port: process.env.API_URL_PORT,
            strictSSL: true,
            timeout: 10000,
            site_key: "tamaran",
        },
        volcanoTeideAPILongConfig: {
            protocol: process.env.API_URL_PROTOCOL,
            host: process.env.API_URL,
            port: process.env.API_URL_PORT,
            strictSSL: true,
            timeout: 15000,
            site_key: "tamaran",
        },
        contactFormURL:
            "https://fyqos3293h.execute-api.eu-west-1.amazonaws.com/pre/contacto",
        appAPIKey: "tamaran",
        statusAPIURL:  `${process.env.API_URL_PROTOCOL}://${process.env.API_URL}/widgets/status`,
        roadStatusURL: "http://cic.tenerife.es/e-Traffic3/Control?act=detMapInci",
        tide1StatusURL:
            "http://www.aemet.es/es/eltiempo/prediccion/playas/la-arena-3804004",
        tide2StatusURL:
            "http://www.aemet.es/es/eltiempo/prediccion/playas/la-ballenita-3801005",
    },
    plugins: [
        `gatsby-plugin-react-helmet`,
        {
            resolve: `gatsby-plugin-sass`,
            options: {
                precision: 6,
            },
        },
        {
            resolve: `volcanoteide-api-plugin`,
            options: {
                baseURL: `${process.env.API_URL_PROTOCOL}://${process.env.API_URL}`,
                languages: languages,
                apiKey: `tamaran`,
                siteId: siteId,
                experiences: experiences,
                resources: [],
            },
        },
        `gatsby-plugin-sharp`,
        `gatsby-transformer-sharp`,
        {
            resolve: `gatsby-plugin-s3`,
            options: {
                bucketName: process.env.S3_BUCKET_NAME,
                generateRoutingRules: false,
                generateRedirectObjectsForPermanentRedirects: false,
            },
        },
        {
            resolve: `gatsby-plugin-react-i18next`,
            options: {
                path: `${__dirname}/src/locales`,
                languages: languages,
                defaultLanguage: ``,

                // you can pass any i18next options
                // pass following options to allow message content as a key
                i18nextOptions: {
                    interpolation: {
                        escapeValue: false, // not needed for react as it escapes by default
                    },
                    keySeparator: false,
                    nsSeparator: ":",
                },
                pages: [
                    {
                        matchPath: "/",
                        getLanguageFromPath: false,
                    },
                    {
                        matchPath: "/booking",
                        getLanguageFromPath: false,
                    },
                    {
                        matchPath: "/404",
                        getLanguageFromPath: false,
                    },
                    {
                        matchPath: "/:lang/checkout/",
                        getLanguageFromPath: true,
                    },
                    {
                        matchPath: "/:lang/checkout/result/",
                        getLanguageFromPath: true,
                    },
                    {
                        matchPath: "/:lang/checkout/payment/",
                        getLanguageFromPath: true,
                    },
                    {
                        matchPath: "/:lang/(.*)",
                        getLanguageFromPath: true,
                    },
                ],
            },
        },
        {
            resolve: `gatsby-plugin-react-helmet-canonical-urls`,
            options: {
                siteUrl: `https://www.tamaran.com`,
            },
        },
    ],
}
